.footer {
  width: 100%;

  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  border-top: solid 2px black;
}
.footer-top-line {
  width: 100vw;
  height: 2px;
  background-color: black;
}

.footer-content {
  display: flex;
  align-items: center;

  justify-content: space-between;

  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  /* padding: 1rem; */
}

.footer-content-wrapper {
  max-width: 1200px;
}

.footer-bottom {
  padding-left: 1rem;
}
