.contactForm {
  font-family: "Swiss", sans-serif;
  width: 50%;
  padding: 1rem;
}

@media (max-width: 767px) {
  .contactForm {
    width: 100%;
    padding: 1rem;
  }

  .imageContainer > img {
    display: none;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-bottom: 2rem;
}

@media (max-width: 767px) {
  .wrapper {
    flex-direction: column;
  }
}

.imageContainer {
  width: 50%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer > img {
  width: 100%;
  height: auto;
  /* border-radius: 2rem; */
  border: 2px solid black;
}

.formInput {
  display: block;
  width: 100%;
  font-size: 1.2em;
  /* padding: 0.5em; */
  margin-bottom: 1em;
  border: 2px solid black;
  padding: 10px;
  box-sizing: border-box;
}

textarea.formInput {
  resize: none;
}

.errorMessage {
  color: red;
  font-size: 0.6em;
  margin-bottom: 1rem;
}

/* .formRow {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 5px;
} */

.errorMessage {
  margin-top: 0px;
  /* Adjust this value to change the distance between the error message and the input */
  color: red; /* Change the color as desired */
  font-size: 0.8rem; /* Adjust the font size as desired */
}

.formRow > .formInput {
  flex: 1;
  min-width: 200px;
  /* margin-right: 15px; */
}

.formInput:focus {
  border-radius: 0;
  outline: 3px solid lightblue;
  /* outline: none; */
  /*border: 3px solid blue;  Adjust the thickness and color as desired */
}

textarea.formInput {
  font-family: sans-serif; /* Change to the desired font */
}

.submit-btn {
  background-color: black;
  color: white;
  font-size: 1.2em;
  padding: 0.5em 1em;
  border: none;
  cursor: pointer;
  transition: transform 0.3s;
}

.submit-btn:hover {
  transform: scale(1.1);
}

/* ::placeholder {
  text-transform: uppercase;
}

:-ms-input-placeholder {
  text-transform: uppercase;
}

::-ms-input-placeholder {
  text-transform: uppercase;
} */
