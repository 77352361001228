/* SlideUpTransition.css */
/* .slide-up-appear {
  transform: translateY(140%);
}

.slide-up-appear-active {
  transform: translateY(0);
  transition: transform 500ms ease;
} */

.slide-up-appear {
  opacity: 1;
}

.slide-up-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}
