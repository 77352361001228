html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: "Swiss", sans-serif;
  font-weight: normal;
  font-size: 22px;

  scroll-snap-type: y mandatory;

  transition: background-color 0.3s ease, color 0.3s ease;
}

:root {
  --background-color: white;
  --text-color: black;
  --logo-color: black;
  --control-color: black;

  height: 100%;
  width: 100%;
}

/* #2fa55a;
 #1e7e41; */

.dark-theme {
  --background-color: black;
  --text-color: white;
  --logo-color: white;
  --control-color: white;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

a {
  text-decoration: none;
  color: var(--text-color);
  font-family: "Swiss", sans-serif;
  font-weight: normal;
  transition: color 0.3s ease, background-color 0.3s ease;
}

a:not(.logo-link):hover {
  color: var(--background-color);
  background-color: var(--text-color);
  fill: var(--background-color);
}

a:not(.logo-link):hover svg {
  fill: var(--background-color);
}

a:hover .light {
  fill: white;
}

a:hover .dark {
  fill: black;
}
