@font-face {
  font-family: "Swiss";
  src: url("./assets/fonts/SuisseIntl-SemiBold-WebM.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

svg {
  fill: #000000;
}
.dark-theme svg {
  fill: white;
}
